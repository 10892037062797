import * as Sentry from "@sentry/browser"

function getRailsEnv() {
  return document.head.querySelector("meta[name=rails_env]").content
}

Sentry.init({
  dsn: "https://72ea239b68cf44db86313dd6fc35ab31:a1b460556c7b4d238794052b04261144@sentry.io/1895422",
  release: "weunloq/campus@latest",
  integrations: [Sentry.browserTracingIntegration()],
  environment: getRailsEnv(),
  enabled: getRailsEnv() === "production" || getRailsEnv() === "staging",
})
